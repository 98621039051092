import React from 'react';
import { Navigate } from 'react-router-dom';
import { getToken } from './Common';
import Dashboard from "./Dashboard";
 
// handle the private routes
function PrivateRoute() {
    const token = getToken();
    return token ? <Dashboard /> : <Navigate to="/" />
}
 
export default PrivateRoute;
import React from 'react';
import { Link } from 'react-router-dom';
import Header from './Header';
import Sidebar from './Sidebar';
import Footer from './Footer';

function Summarydetails() {
    let medDetails = JSON.parse(localStorage.getItem('medDetails'));
    return (
      <>
          <div className="wrapper">
              {<Header />}
              {<Sidebar />}
              
  
              <div className="content-wrapper">
                <section className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-12">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item"><Link to="/Dashboard">Home</Link></li>
                                    <li className="breadcrumb-item active">Medication Details</li>
                                </ol>
                            </div>
                            <div className="col-sm-12">
                                <h1>Medication Details</h1>
                            </div>

                        </div>
                    </div>
                </section>
                <section className="content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="card">
                                    <div className="card-body p-0">  
                                        <div className="col-lg-12">                                      
                                            <ul className="products-list product-list-in-card pl-2 pr-2">
                                                <li className="item">
                                                    <div className="row">
                                                        <div className="product-img">
                                                            {
                                                                medDetails.logoUrl != null ? <img src={medDetails.logoUrl} alt={medDetails.drugName} className="img-size-50" width="100" /> : ''
                                                            }
                                                        </div>
                                                    </div>
                                                    <span className="product-description">
                                                        <strong>{medDetails.drugName}</strong>
                                                    </span>
                                                    <div className="row mt-3">
                                                        <div className="col-8">
                                                            <ul className="list-unstyled fs-13">
                                                                <li>Active Ingredient : 
                                                                    <ul>
                                                                        {medDetails.actIngredient && medDetails.actIngredient.map((itm, idx) => (
                                                                            <li key={idx}>{itm.name}</li>
                                                                        ))}
                                                                    </ul>
                                                                </li>
                                                                <li>Year Approved : {medDetails.yearOfApprove}</li>
                                                            </ul>
                                                        </div>
                                                        <div className="col-4">
                                                            <ul className="list-unstyled fs-13">
                                                                <li>Black Box Warning</li>
                                                                <div className="color-palette-set">
                                                                    {
                                                                        medDetails.blackBox !== 0 ? <div className="bg-black color-palette black-box"><span></span></div> : <div className="bg-white color-palette white-box"><span>No</span></div>
                                                                    }
                                                                </div>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </li>
                                                <li className="item">
                                                    <dl>
                                                        <dt>
                                                            <i className="fas fa-circle" style={{ color: medDetails.medicationColour }}></i> Medical History Score {/* : {medDetails.medicationScore} */}
                                                        </dt>
                                                        <ul className="list-unstyled">
                                                            <li>
                                                                DO NOT TAKE:
                                                                <ul>
                                                                    {medDetails.doNotTake.length === 0 ? <li>No Medical issue</li> : medDetails.doNotTake.map((itm, idx) => (
                                                                        <li key={idx}>{itm}</li>
                                                                    ))}
                                                                </ul>
                                                            </li>
                                                            <li>
                                                                WARNING: (ask your doctor)
                                                                <ul>
                                                                    {medDetails.wanrning.length === 0 ? <li>No Medical issue</li> : medDetails.wanrning.map((itm, idx) => (
                                                                        <li key={idx}>{itm}</li>
                                                                    ))}
                                                                </ul>
                                                            </li>
                                                        </ul>
                                                    </dl>
                                                    <dl>
                                                        <dt>
                                                            <i className="fas fa-circle" style={{ color: medDetails.adminColour }}></i> Administration Score {/* : {medDetails.adminScore} */}
                                                        </dt>
                                                        <ul>
                                                            {medDetails.adminDetails && medDetails.adminDetails.map((itm, idx) => (
                                                                <li key={idx}>{itm}</li>
                                                            ))}
                                                        </ul>
                                                    </dl>
                                                    <dl>
                                                        <dt>
                                                        Administrative Restriction : 
                                                        </dt>
                                                        {
                                                            medDetails.adminRestriction ? <ul><li>{medDetails.adminRestriction}</li></ul> : ''
                                                        }
                                                        
                                                    </dl>
                                                    <dl>
                                                        <dt>
                                                            Side effects
                                                        </dt>
                                                        <ul>
                                                            {Object.values(medDetails.sideEffect).map(data => {
                                                                return(
                                                                    <li>
                                                                        {data.sideEffect}
                                                                    </li>
                                                                )
                                                            })}
                                                        </ul>
                                                    </dl>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="col-lg-4">
                                        </div>
                                        <div className="col-lg-4">
                                            <Link to="/Medicalhistory" className="btn btn-sm btn-primary" style={{marginBottom: "20px"}}>Back</Link>
                                        </div>
                                        <div className="col-lg-4">
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </section>
              </div>
  
              
              {<Footer />}
          </div>
      </>
    );
}

export default Summarydetails;

import React, { useState } from 'react';
import { useLocation, Link } from 'react-router-dom';
import Header from './Header';
import Sidebar from './Sidebar';
import Footer from './Footer';
import { getUser, getMethod, getFreq } from './Common';

function Dashboard() {
    const user = getUser();
    const method = getMethod();
    const freq = getFreq();
    const [error, setError] = useState((method && freq)?"Administration Preferences set, please Select Disease":"Please Select Administration Preferences");
    let selectDisease;
    if (method && freq) {
        selectDisease = <Link to="/Disease" className="btn btn-sm btn-success btn-block" style={{padding: "10px 20px"}}>
        <h4>Select Disease</h4></Link>;
    } else {
        selectDisease = <Link to="#" className="btn btn-sm btn-success btn-block" style={{padding: "10px 20px"}}>
        <h4>Select Disease</h4></Link>;
        // setError("This version supports only one disease to be selected");
    }
    //assigning location variable
    const location = useLocation();

    //destructuring pathname from location
    const { pathname } = location;

    //Javascript split method to get the name of the path in array
    const splitLocation = pathname.split("/");
    return (
        <>
            <div className="wrapper">
                {<Header />}
                {<Sidebar />}


                <div className="content-wrapper">
                    <section className="content-header">
                        <div className="container-fluid">
                            <div className="row mb-2">
                                <div className="col-sm-12">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item"><a href="#">Home</a></li>
                                    </ol>
                                </div>
                                <div className="col-sm-12">
                                    <h1 className="m-0">Dashboard</h1>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="content">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="card card-blue">
                                    <div className="card-header">
                                        <h4>Hello, {user.name}</h4>
                                    </div>
                                    <div className="card-body">
                                        <div className="col-md-10 offset-md-1 pt-3">
                                            <div className="row">
                                                <div className="col-md-12">
                                                    {error && <><span style={{ color: 'red' }}>{error}</span></>}
                                                </div>
                                                <div className="col-lg-4">
                                                    <Link to="/Preference">
                                                        <div className="small-box bg-info">
                                                            <div className="inner">
                                                                <h4>Administration Preferences</h4>

                                                                <p></p>
                                                                <p></p>
                                                                <p></p>
                                                            </div>
                                                            <div className="icon">
                                                                <i className="fas fa-syringe"></i>
                                                            </div>
                                                        </div>
                                                    </Link>
                                                </div>
                                                <div className="col-lg-4">
                                                    <Link to="/CounterMedicine">
                                                        <div className="small-box bg-info">
                                                            <div className="inner">
                                                                <h4>Over The Counter Medication</h4>

                                                                <p></p>
                                                                <p></p>
                                                                <p></p>
                                                            </div>
                                                            <div className="icon">
                                                                <i className="fas fa-syringe"></i>
                                                            </div>
                                                        </div>
                                                    </Link>
                                                </div>
                                                <div className="col-lg-4">
                                                    <Link to="/Supplement">
                                                        <div className="small-box bg-info">
                                                            <div className="inner">
                                                                <h4>Nutritional/Supplement</h4>

                                                                <p></p>
                                                                <p></p>
                                                                <p></p>
                                                            </div>
                                                            <div className="icon">
                                                                <i className="fas fa-syringe"></i>
                                                            </div>
                                                        </div>
                                                    </Link>
                                                </div>
                                                <div className="col-lg-12">
                                                    <img  src="assets/dist/img/BlueLine.png" style={{width: "100%"}} />
                                                </div>
                                                <div className="col-lg-4">
                                                </div>
                                                <div className="col-lg-4 text-center">
                                                    {selectDisease}
                                                </div>
                                                <div className="col-lg-4">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </section>
                </div>


                {<Footer />}
            </div>
        </>
    );
}

export default Dashboard;

import React from 'react';
import { useLocation, Link } from 'react-router-dom';
import { getUser } from './Common';

function Sidebar() {
    const user = getUser();

    //assigning location variable
    const location = useLocation();

    //destructuring pathname from location
    const { pathname } = location;

    //Javascript split method to get the name of the path in array
    const splitLocation = pathname.split("/");

    return (
        <>
            <aside className="main-sidebar sidebar-light-primary elevation-4 main-sidebar sidebar-dark-primary elevation-4">
                <div className="brand-link bg-primary">
                    <img src="assets/dist/img/icon.png" alt="Logo" className="brand-image img-circle elevation-3 logo" />
                    <span className="brand-text font-weight-light"><b>Medif</b>Vu</span>
                </div>
                <div className="sidebar">
                    <div className="user-panel mt-3 pb-3 mb-3 d-flex">
                        
                        <div className="info">
                            <a href="#" className="d-block">{user.name}</a>
                        </div>
                    </div>
                    <div className="form-inline">
                        <div className="input-group" data-widget="sidebar-search">
                            <input className="form-control form-control-sidebar" type="search" placeholder="Search" aria-label="Search" />
                            <div className="input-group-append">
                                <button className="btn btn-sidebar">
                                    <i className="fas fa-search fa-fw"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                    <nav className="mt-2 nav-font">
                        <ul className="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
                            <li className="nav-item">
                                <Link to="/Dashboard" className={splitLocation[1] === "Dashboard" ? "nav-link active" : "nav-link"}>
                                    <i className="nav-icon fas fa-tachometer-alt"></i>
                                    <p>Dashboard</p>
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link to="/Preference" className={splitLocation[1] === "Preference" ? "nav-link active" : "nav-link"}>
                                    <i className="fas fa-briefcase-medical nav-icon"></i>
                                    <p>Administration Preferences</p>
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link to="/Disease" className={splitLocation[1] === "Disease" ? "nav-link active" : "nav-link"}>
                                    <i className="nav-icon fas fa-disease"></i>
                                    <p>Disease</p>
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link to="/CounterMedicine" className={"nav-link"}>
                                    <i className="fas fa-file-medical nav-icon"></i>
                                    <p>Over The Counter</p>
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link to="/Supplement" className={"nav-link"}>
                                    <i className="fas fa-capsules nav-icon"></i>
                                    <p>Nutritional/Supplement</p>
                                </Link>
                            </li>
                        </ul>
                    </nav>
                </div>
            </aside>
        </>
    );
}

export default Sidebar;

import React from "react";
import { BrowserRouter as Router, Routes, Route, Nav, Link } from 'react-router-dom';
import Login from "./components/Login";
import Dashboard from "./components/Dashboard";
import Preference from "./components/Preference";
import Disease from "./components/Disease";
import Summary from "./components/Summary";
import Summarydetails from "./components/Summarydetails";
import CounterMedicine from "./components/CounterMedicine";
import Supplement from "./components/Supplement";
import Medicalhistory from "./components/Medicalhistory";
import PrivateRoute from './components/PrivateRoute';
import PublicRoute from './components/PublicRoute';
import DiseaseRoute from './components/DiseaseRoute';

function App() {
  return (
    <>
      <Router>
        <Routes>
          <Route path="/" element={<PublicRoute />} />
          <Route path="/Dashboard" element={<PrivateRoute />} />
          <Route path="/Preference" element={<Preference />} />
          <Route path="/Disease" element={<DiseaseRoute />} />
          <Route path="/Summary" element={<Summary />} />
          <Route path="/Summarydetails" element={<Summarydetails />} />
          <Route path="/Medicalhistory" element={<Medicalhistory itemsPerPage={5} />} />
          <Route path="/CounterMedicine" element={<CounterMedicine />} />
          <Route path="/Supplement" element={<Supplement />} />
        </Routes>
      </Router>
    </>
  );
}

export default App;

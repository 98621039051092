import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import Header from './Header';
import Sidebar from './Sidebar';
import Footer from './Footer';
import { getUser, getToken } from './Common';

function Disease() {
    const user = getUser();
    const token = getToken();
    // State to store value from the input field
    const [selDis, setSelDis] = useState("");
    const [selMis, setSelMis] = useState("");
    const [isloaded, setIsloaded] = useState(false);
    const [error, setError] = useState(null);
  
    // Input Field handler
    const handleChangeD = (e) => {
        setSelDis(e.target.value);
        setSelMis("");
        // setError("This version supports only one disease to be selected");
    };
  
    // Reset Input Field handler
    const handleChangeM = (e) => {
        setSelMis(e.target.value);
        setSelDis("");
        // setError("This version supports only one disease to be selected");
    };

    let navigate = useNavigate();
    const [disease, setDisease] = useState([]);
    const [multi, setMulti] = useState([]);
    const [mode, setMode] = useState('online');
    const [medicine, setMedicine] = useState([]);
    useEffect(() => {
        let apiUrl = "http://18.213.251.171:8080/ihealth-0.0.2/disease/subDisease?dideaseId=1";
        fetch(apiUrl, {
            method: "GET",
            headers: {"Authorization": `Bearer ${token}`}
          }).then((response) => {
            response.json().then((result) => {
                setDisease(result.content);
            })
        })
        .catch(err=>{
            setMode('offline');
            setDisease([]);
        });
    }, []);
    useEffect(() => {
        let apiUrl = "http://18.213.251.171:8080/ihealth-0.0.2/disease/subDisease?dideaseId=2";
        fetch(apiUrl, {
            method: "GET",
            headers: {"Authorization": `Bearer ${token}`}
          }).then((response) => {
            response.json().then((result) => {
                setMulti(result.content);
            })
        })
        .catch(err=>{
            setMode('offline');
            setMulti([]);
        });
    }, []);

    const handleDisease = (e) => {
        e.preventDefault();
        const data = new FormData(e.target);    
        // const value = Object.fromEntries(data.forEach());
        // Object.entries(value).map(([ key, val ]) => [ 
        //     console.log({ key })
        // ]);
        // var did = [], freq = [];
        for (var pair of data.entries()) {
            if(pair[0] === 'dis'){
                if(pair[1] != ''){
                    var diseaseId = pair[1];
                    break;
                }
            }
            else{
                if(pair[0] === 'mis'){
                    if(pair[1] != ''){
                        var diseaseId = pair[1];
                    }
                }
            }
        }
        // console.log({method});
        // console.log({freq}); 
        if(diseaseId){
            console.log({diseaseId});
            localStorage.setItem('disease',diseaseId);
        }
        // if(freq.length > 0){
        //     localStorage.setItem('freq',JSON.stringify(freq));
        // }
        if(diseaseId){
            setTimeout(()=>{
                navigate('/Medicalhistory');
            }, 2000)
        }
        else{
            localStorage.removeItem('disease');
        }
    }

    
    useEffect(() => {
        let apiUrl = "http://18.213.251.171:8080/ihealth-0.0.2/score/medHistory";
        let diseaseId = localStorage.getItem('disease');
        let patientId = user.patientId;
        const json_arr = {};
        json_arr.diseaseId = parseInt(diseaseId);

        let freqs = JSON.parse(localStorage.getItem('freq'));
        const frq = {};
        for (var pair of freqs) {
            var str = pair.split('_');
            var x = str[0];
            var y = str[1];
            frq[y] = x;
        }
        json_arr.freq = frq;

        let methods = JSON.parse(localStorage.getItem('method'));
        const mth = {};
        for (var pair of methods) {
            var str = pair.split('_');
            var x = str[0];
            var y = str[1];
            mth[y] = x;
        }
        json_arr.method = mth;

        json_arr.patientId = patientId;
        const json = JSON.stringify(json_arr);
        //   console.log(json);
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${token}`},
            body: json
        };
        fetch(apiUrl, requestOptions).then((response) => {
            response.json().then((result) => {
                //   console.log(result);
                localStorage.setItem('medicines', JSON.stringify(result.content));
                setIsloaded(true);
                setMedicine(result.content);
            })
        }).catch(err => {
            setMode('offline');
            let medicines = localStorage.getItem('medicines');
            setIsloaded(false);
            setMedicine(medicines);
        });
    }, [handleDisease]);

    return (
        <>
            <div className="wrapper">
                {<Header />}
                {<Sidebar />}
                

                <div className="content-wrapper">
                    <section className="content-header">
                        <div className="row mb-2">
                            <div className="col-sm-12">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item"><Link to="/Dashboard">Home</Link></li>
                                    <li className="breadcrumb-item active">Patient Disease</li>
                                </ol>
                            </div>
                        </div>
                    </section>
                    <section className="content">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-10 offset-md-1">
                                    <form onSubmit={handleDisease}>
                                        <div className="card card-primary">
                                            <div className="card-header">
                                                <h3 className="card-title">Patient Disease</h3>
                                            </div>
                                            <div className="card-body">
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        {error && <><span style={{ color: 'red' }}>{error}</span></>}
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label>
                                                                Diabetes
                                                            </label>
                                                            <select className="form-control" name="dis" value={selDis} onChange={handleChangeD}>
                                                                <option value="">-- Select --</option>
                                                                {disease.map((option,indx) => (
                                                                <option key={indx} value={option.id}>{option.name}</option>
                                                                ))}
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label>Multiple Sclerosis</label>
                                                            <select className="form-control" name="mis" value={selMis} onChange={handleChangeM}>
                                                                <option value="">-- Select --</option>
                                                                {multi.map((option,indx) => (
                                                                <option key={indx} value={option.id}>{option.name}</option>
                                                                ))}
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>

                                                <br />
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label>
                                                                Heart Disease
                                                            </label>
                                                            <select className="form-control" disabled id="heartattack">
                                                                <option>-- Select --</option>
                                                                <option value="1">
                                                                    Adult Type II Diabetes Mellitus (first line treatment)
                                                                </option>
                                                                <option value="2">
                                                                    Adult Type II Diabetes Mellitus (second line treatment)
                                                                </option>
                                                                <option>
                                                                    Pediatric Type II Diabetes (10+ yrs old)
                                                                </option>
                                                                <option>
                                                                    Adult Type I diabetes mellitus
                                                                </option>
                                                                <option>
                                                                    Pediatric Type I Diabetes (10+ yrs old)
                                                                </option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label>Psychiatric</label>
                                                            <select className="form-control" disabled>
                                                                <option>-- Select --</option>
                                                                <option>
                                                                    Primary Progressive MS (PPMS)
                                                                </option>
                                                                <option>
                                                                    Clinically isolated syndrome (CIS)
                                                                </option>
                                                                <option>
                                                                    Relapsing-remitting MS disease (RRMS)
                                                                </option>
                                                                <option>
                                                                    Secondary progressive MS disease (SPMS)
                                                                </option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="card-footer">
                                                <button type="submit" className="btn btn-primary">Get Recommendation</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>

                        </div>
                    </section>
                </div>

                
                {<Footer />}
            </div>
        </>
    );
}

export default Disease;

import React from 'react';
import { Link } from 'react-router-dom';
import Header from './Header';
import Sidebar from './Sidebar';
import Footer from './Footer';

function Supplement() {
    return (
        <>
            <div className="wrapper">
                {<Header />}
                {<Sidebar />}

                <div className="content-wrapper">
                    <section className="content-header">
                        <div className="container-fluid">
                            <div className="row mb-2">
                                <div className="col-sm-12">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item"><Link to="/Dashboard">Home</Link></li>
                                        <li className="breadcrumb-item active">Nutritional/Supplement</li>
                                    </ol>
                                </div>
                                <div className="col-sm-12">
                                    <h1>Nutritional/Supplement</h1>
                                </div>
                            </div>
                        </div>
                    </section>
                    <div className="content">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="alert alert-warning alert-dismissible">
                                    {/* <button type="button" className="close" data-dismiss="alert" aria-hidden="true">&times;</button> */}
                                    <img src="https://pngimg.com/uploads/under_construction/under_construction_PNG23.png" alt="Alternate Text" width="50" />
                                    <strong>Page Under Construction</strong>
                                </div>
                                <div className="card card-primary">
                                    <div className="card-header">
                                        <h3 className="card-title">
                                            Please enter Nutritional/Supplement, units and frequency:
                                        </h3>
                                    </div>
                                    <form action="/Home" method="post">
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col-md-4">
                                                    <div className="form-group">
                                                        <label>Name</label>
                                                        <select className="form-control select2" disabled>
                                                            <option>-- Select --</option>
                                                            <option>A</option>
                                                            <option>B</option>
                                                            <option>C</option>
                                                            <option>D</option>
                                                            <option>E</option>
                                                            <option>F</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                    <div className="form-group">
                                                        <label>Units</label>
                                                        <select className="form-control select2" disabled>
                                                            <option>-- Select --</option>
                                                            <option>Mg</option>
                                                            <option>Ml</option>
                                                            <option>Ounce</option>

                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                    <label>Frequency</label>
                                                    <select className="form-control select2" disabled>
                                                        <option>-- Select --</option>
                                                        <option>Daily</option>
                                                        <option>Weekly</option>
                                                        <option>Monthly</option>

                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card-footer">
                                            <Link to="/" className="btn btn-primary">Submit</Link>
                                        </div>
                                    </form>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                {<Footer />}
            </div>
        </>
    );
}

export default Supplement;

import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Header from './Header';
import Sidebar from './Sidebar';
import Footer from './Footer';
import { getUser } from './Common';

function Summary() {
    const user = getUser();
    const [medicine, setMedicine] = useState([]);
    const [mode, setMode] = useState('online');
    useEffect(() => {
        let apiUrl = "/score/medHistory";
        let diseaseId = localStorage.getItem('disease');
        let patientId = user.patientId;
        const json_arr = {};
        json_arr.diseaseId = parseInt(diseaseId);
        
        let freqs = JSON.parse(localStorage.getItem('freq'));
        const frq = {};
        for (var pair of freqs) {
            var str = pair.split('_');
            var x = str[0];
            var y = str[1];
            frq[y] = x;
        }
        json_arr.freq = frq;
        
        let methods = JSON.parse(localStorage.getItem('method'));
        const mth = {};
        for (var pair of methods) {
            var str = pair.split('_');
            var x = str[0];
            var y = str[1];
            mth[y] = x;
        }
        json_arr.method = mth;

        json_arr.patientId = patientId;
        const json = JSON.stringify(json_arr);
        console.log(json);
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: json
        };
        fetch(apiUrl, requestOptions).then((response) => {
            response.json().then((result) => {
                console.log(result);
                setMedicine(result.content);
            })
        })
        .catch(err=>{
            setMode('offline');
            // let collection = localStorage.getItem('methods');
            setMedicine([]);
        });
    }, []);
    console.log({ medicine });
    return (
        <>
            <div className="wrapper">
                {<Header />}
                {<Sidebar />}
                
    
                <div className="content-wrapper">
                    <section className="content mt-2">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-md-12 col-sm-6">
                                    <div className="card card-primary card-tabs">
                                        <div className="card-header p-0 pt-1">
                                            <ul className="nav nav-tabs" id="custom-tabs-one-tab" role="tablist">
                                                <li className="nav-item">
                                                    <a className="nav-link active" id="custom-tabs-one-profile-tab" data-toggle="pill" href="#custom-tabs-one-profile" role="tab" aria-controls="custom-tabs-one-profile" aria-selected="false">Medical Condition</a>
                                                </li>
                                                <li className="nav-item">
                                                    <a className="nav-link" id="custom-tabs-one-messages-tab" role="tab" aria-controls="custom-tabs-one-messages" aria-selected="false">Active Ingrediants</a>
                                                </li>
                                                <li className="nav-item">
                                                    <a className="nav-link" id="custom-tabs-one-messages-tab" role="tab" aria-controls="custom-tabs-one-messages" aria-selected="false">Year Approved</a>
                                                </li>
                                                <li className="nav-item">
                                                    <a className="nav-link" id="custom-tabs-one-messages-tab" role="tab" aria-controls="custom-tabs-one-messages" aria-selected="false">Manufacturers</a>
                                                </li>
                                                <li className="nav-item">
                                                    <a className="nav-link" id="custom-tabs-one-messages-tab" role="tab" aria-controls="custom-tabs-one-messages" aria-selected="false">Insurance</a>
                                                </li>
                                                
                                            </ul>
                                        </div>
                                        <div className="card-body">
                                            <div className="tab-content" id="custom-tabs-one-tabContent">
                                                <div className="tab-pane fade show active" id="custom-tabs-one-profile" role="tabpanel" aria-labelledby="custom-tabs-one-profile-tab">
                                                    {
                                                        medicine.map((item,indx)=>(
                                                            <Link to="/Disease" key={indx}>
                                                                <div className="card">
                                                                    <div className="card-body p-0">
                                                                        <ul className="products-list product-list-in-card pl-2 pr-2">
                                                                            <li className="item">
                                                                                <div className="product-img">
                                                                                    {
                                                                                        item.logoUrl != null ? <img src={item.logoUrl} alt={item.drugName} className="img-size-50" width="100"/> : item.drugName
                                                                                    }
                                                                                </div>
                                                                                <div className="product-info">
                                                                                    <div className="row">
                                                                                        <div className="col-sm-12">
                                                                                            <div className="form-group clearfix fs-13">
                                                                                                <div className="icheck-danger d-inline">                                                                                                
                                                                                                    <label htmlFor="radioPrimary1">
                                                                                                        <i className='fas fa-circle' style={{color: item.medicationColour}}></i>
                                                                                                        Medical History Score
                                                                                                    </label>
                                                                                                </div>
                                                                                                <div className="icheck-warning d-inline">                                                                                                
                                                                                                    <label htmlFor="radioPrimary2">
                                                                                                        <i className='fas fa-circle' style={{color: item.adminColour}}></i>
                                                                                                        Administration Score
                                                                                                    </label>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </li>
                                                                            <li className="item">
                                                                                <div className="row">
                                                                                    <div className="col-8">
                                                                                        <ul className="list-unstyled fs-13">
                                                                                            <li>Active Ingredient : {item.actIngredient.name}</li>
                                                                                            <li>Year Approved : {item.yearOfApprove}</li>
                                                                                        </ul>
                                                                                    </div>
                                                                                    <div className="col-4">
                                                                                        <ul className="list-unstyled fs-13">
                                                                                            <li>Black Box Warning</li>
                                                                                            <div className="color-palette-set">
                                                                                                {
                                                                                                    item.blackBox != 0 ? <div className="bg-black color-palette black-box"><span></span></div> : <div className="bg-white color-palette white-box"><span>No</span></div>
                                                                                                }
                                                                                            </div>
                                                                                        </ul>
                                                                                    </div>
                                                                                </div>
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                            </Link>
                                                        ))
                                                    }
                                                </div>
                                                <div className="tab-pane fade" id="custom-tabs-one-messages" role="tabpanel" aria-labelledby="custom-tabs-one-messages-tab">
                                                    
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
    
                
                {<Footer />}
            </div>
        </>
    );
}

export default Summary;

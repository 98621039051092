import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ReactPaginate from 'react-paginate';
import Header from './Header';
import Sidebar from './Sidebar';
import Footer from './Footer';
import { getUser } from './Common';

function Medicalhistory({ itemsPerPage }) {
    let navigate = useNavigate();
    let medicines = JSON.parse(localStorage.getItem('medicines'));
    let startPage = localStorage.getItem('pageView');
    if(!startPage){
        startPage = 0;
    }
    const user = getUser();
    const [medicine, setMedicine] = useState(medicines);
    const startOffset = (startPage * itemsPerPage) % medicine.length;
    // const [mode, setMode] = useState('online');
    // const [isloaded, setIsloaded] = useState(false);
    const [currentItems, setCurrentItems] = useState([]);
    const [pageCount, setPageCount] = useState(0);
    const [itemOffset, setItemOffset] = useState(0);

    useEffect(() => {
        // console.log(medicine);
        const endOffset = itemOffset + itemsPerPage;
        // console.log(`Loading items from ${itemOffset} to ${endOffset}`);
        setCurrentItems(medicine.slice(itemOffset, endOffset));
        setPageCount(Math.ceil(medicine.length / itemsPerPage));
    }, [itemOffset, itemsPerPage]);

    const handlePageClick = (event) => {
        const newOffset = (event.selected * itemsPerPage) % medicine.length;
        localStorage.setItem('pageView',event.selected);
        // console.log(
        //     `User requested page number ${event.selected}, which is offset ${newOffset}`
        // );
        setItemOffset(newOffset);
    };

    const handleMedDetails = (e) => {
        e.preventDefault();
        console.log(e.currentTarget.id);
        localStorage.setItem('medDetails',e.currentTarget.id);
        navigate('/Summarydetails');
    }
    return (
        <>
            <div className="wrapper">
                {<Header />}
                {<Sidebar />}


                <div className="content-wrapper">
                    <section className="content mt-2">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-md-12 col-sm-6">
                                    <div className="card card-primary card-tabs">
                                        <div className="card-header p-0 pt-1">
                                            <ul className="nav nav-tabs" id="custom-tabs-one-tab" role="tablist">
                                                <li className="nav-item">
                                                    <a className="nav-link active" id="custom-tabs-one-profile-tab" data-toggle="pill" href="#custom-tabs-one-profile" role="tab" aria-controls="custom-tabs-one-profile" aria-selected="false">Medical Condition</a>
                                                </li>
                                                <li className="nav-item">
                                                    <a className="nav-link" id="custom-tabs-one-messages-tab" role="tab" aria-controls="custom-tabs-one-messages" aria-selected="false">Active Ingrediants</a>
                                                </li>
                                                <li className="nav-item">
                                                    <a className="nav-link" id="custom-tabs-one-messages-tab" role="tab" aria-controls="custom-tabs-one-messages" aria-selected="false">Year Approved</a>
                                                </li>
                                                <li className="nav-item">
                                                    <a className="nav-link" id="custom-tabs-one-messages-tab" role="tab" aria-controls="custom-tabs-one-messages" aria-selected="false">Manufacturers</a>
                                                </li>
                                                <li className="nav-item">
                                                    <a className="nav-link" id="custom-tabs-one-messages-tab" role="tab" aria-controls="custom-tabs-one-messages" aria-selected="false">Insurance</a>
                                                </li>

                                            </ul>
                                        </div>
                                        <div className="card-body">
                                            <div className="tab-content" id="custom-tabs-one-tabContent">
                                                <div className="tab-pane fade show active" id="custom-tabs-one-profile" role="tabpanel" aria-labelledby="custom-tabs-one-profile-tab">
                                                    {
                                                        currentItems &&
                                                        currentItems.map((item, indx) => (
                                                            <a href="#" key={indx} id={JSON.stringify(item)} onClick={handleMedDetails}>
                                                                <div className="card">
                                                                    <div className="card-body p-0">
                                                                        <ul className="products-list product-list-in-card pl-2 pr-2">
                                                                            <li className="item">
                                                                                <div className="product-img">
                                                                                    {
                                                                                        item.logoUrl != null ? <img src={item.logoUrl} alt={item.drugName} className="img-size-50" width="100" /> : item.drugName
                                                                                    }
                                                                                </div>
                                                                                <div className="product-info">
                                                                                    <div className="row">
                                                                                        <div className="col-sm-12">
                                                                                            <div className="form-group clearfix fs-13">
                                                                                                <div className="icheck-danger d-inline">
                                                                                                    <label htmlFor="radioPrimary1">
                                                                                                        <i className='fas fa-circle' style={{ color: item.medicationColour }}></i>
                                                                                                        Medical History Score
                                                                                                    </label>
                                                                                                </div>
                                                                                                <div className="icheck-warning d-inline">
                                                                                                    <label htmlFor="radioPrimary2">
                                                                                                        <i className='fas fa-circle' style={{ color: item.adminColour }}></i>
                                                                                                        Administration Score
                                                                                                    </label>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </li>
                                                                            <li className="item">
                                                                                <div className="row">
                                                                                    <div className="col-8">
                                                                                        <ul className="list-unstyled fs-13">
                                                                                            <li>Active Ingredient : 
                                                                                                <ul>
                                                                                                    {item.actIngredient && item.actIngredient.map((itm, idx) => (
                                                                                                        <li key={idx}>{itm.name}</li>
                                                                                                    ))}
                                                                                                </ul>
                                                                                            </li>
                                                                                            <li>Year Approved : {item.yearOfApprove}</li>
                                                                                        </ul>
                                                                                    </div>
                                                                                    <div className="col-4">
                                                                                        <ul className="list-unstyled fs-13">
                                                                                            <li>Black Box Warning</li>
                                                                                            <div className="color-palette-set">
                                                                                                {
                                                                                                    item.blackBox != 0 ? <div className="bg-black color-palette black-box"><span></span></div> : <div className="bg-white color-palette white-box"><span>No</span></div>
                                                                                                }
                                                                                            </div>
                                                                                        </ul>
                                                                                    </div>
                                                                                </div>
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                            </a>
                                                        ))
                                                    }
                                                    
                                                    <ReactPaginate
                                                        breakLabel="..."
                                                        nextLabel=">"
                                                        onPageChange={handlePageClick}
                                                        pageRangeDisplayed={5}
                                                        pageCount={pageCount}
                                                        previousLabel="<"
                                                        renderOnZeroPageCount={null}
                                                        breakClassName={'page-item'}
                                                        breakLinkClassName={'page-link'}
                                                        containerClassName={'pagination'}
                                                        pageClassName={'page-item'}
                                                        pageLinkClassName={'page-link'}
                                                        previousClassName={'page-item'}
                                                        previousLinkClassName={'page-link'}
                                                        nextClassName={'page-item'}
                                                        nextLinkClassName={'page-link'}
                                                        activeClassName={'active'}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>


                {<Footer />}
            </div>
        </>
    );
}

export default Medicalhistory;
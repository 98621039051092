import React from 'react';
import { Navigate } from 'react-router-dom';
// import { getToken } from './Common';
import Preference from "./Preference";
import Disease from "./Disease";

// handle the private routes
function DiseaseRoute() {
    let freqs = JSON.parse(localStorage.getItem('freq'));
    let methods = JSON.parse(localStorage.getItem('method'));
    return freqs && methods ? <Disease /> : <Navigate to="/Preference" />
}
 
export default DiseaseRoute;
import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import Header from './Header';
import Sidebar from './Sidebar';
import Footer from './Footer';
import { getToken } from './Common';

function Preference() {
    const token = getToken();
    console.log(token);
    let navigate = useNavigate();
    const [error, setError] = useState(null);
    const [data, setData] = useState([]);
    const [datas, setDatas] = useState([]);
    const [mode, setMode] = useState('online');
    useEffect(() => {
        let apiUrl = "http://18.213.251.171:8080/ihealth-0.0.2/administration/getMethod";
        fetch(apiUrl, {
            method: "GET",
            headers: {"Authorization": `Bearer ${token}`}
          }).then((response) => {
            response.json().then((result) => {
                // localStorage.setItem('methods',JSON.stringify(result.content));
                // console.log(result);
                setData(result.content);
            })
        })
        .catch(err=>{
            setMode('offline');
            // let collection = localStorage.getItem('methods');
            setData([]);
        });
    }, []);
    useEffect(() => {
        let apiUrl2 = "http://18.213.251.171:8080/ihealth-0.0.2/administration/getFreq";
        fetch(apiUrl2, {
            method: "GET",
            headers: {"Authorization": `Bearer ${token}`}
          }).then((response) => {
            response.json().then((result) => {
                // localStorage.setItem('freq',JSON.stringify(result.content));
                // console.log(result);
                setDatas(result.content);
            })
        })
        .catch(err=>{
            setMode('offline');
            // let collection = localStorage.getItem('freq');
            setDatas([]);
        });
    }, []);
    const handleMethod = (e) => {
        e.preventDefault();
        const data = new FormData(e.target);    
        // const value = Object.fromEntries(data.forEach());
        // Object.entries(value).map(([ key, val ]) => [ 
        //     console.log({ key })
        // ]);
        var method = [], freq = [];
        for (var pair of data.entries()) {
            var str = pair[0].split('_');
            if(str[0] === 'Method'){
                method.push(pair[1]);
            }
            else{
                freq.push(pair[1]);
            }
        }
        // console.log({method});
        // console.log({freq}); 
        if(method.length > 0){
            localStorage.setItem('method',JSON.stringify(method));
        }
        if(freq.length > 0){
            localStorage.setItem('freq',JSON.stringify(freq));
        }
        if(method.length > 0 && freq.length > 0){
            navigate('/Dashboard');
        }
        else{
            setError("At least one administrative method and one frequency needs to be selected");
            localStorage.removeItem('method');
            localStorage.removeItem('freq');
            localStorage.removeItem('disease');
            localStorage.removeItem('medicines');
            localStorage.removeItem('medDetails');
        }
    }
    return (
        <>
            <div className="wrapper">
                {<Header />}
                {<Sidebar />}
                <div className="content-wrapper">
                    <section className="content-header">
                        <div className="container-fluid">
                            <div className="row mb-2">
                                <div className="col-sm-12">
                                    {
                                        mode === 'offline'?
                                        <div className="alert alert-warning" role="alert">You are in offline mode</div>
                                        :null
                                    }
                                </div>
                                <div className="col-sm-12">
                                    <h1>Administration Preferences</h1>
                                </div>
                                <div className="col-sm-12">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item"><Link to="/Dashboard">Home</Link></li>
                                        <li className="breadcrumb-item active">Administration Preferences</li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </section>
                    <div className="content">
                        <div className="row">
                            <form onSubmit={handleMethod}>
                                <div className="col-md-12">
                                    <div className="card card-primary">
                                        <div className="card-header">
                                            <h3 className="card-title">
                                                What is your preferred administration method?
                                            </h3>
                                        </div>
                                        <div className="card-body">
                                            <div className="row">
                                                {
                                                    data.map((item,indx)=>(
                                                        <div key={indx} className="col-md-4">
                                                            <label>{item.name}</label>
                                                            <div className="form-group fs-10">
                                                                <div className="radio-wrap text-center mr-3">
                                                                    <div className="radio-label-fake text-center">Never</div>
                                                                    <input type="radio" id={"radio_method_never_" + item.id} value={"1_" + item.id} name={"Method_" + item.id} className="text-center" />
                                                                    <label htmlFor={"radio_method_never_" + item.id}></label>
                                                                </div>
                                                                <div className="radio-wrap text-center mr-3">
                                                                    <div className="radio-label-fake text-center">Not Preferred</div>
                                                                    <input type="radio" id={"radio_method_notpref_" + item.id} value={"3_" + item.id} name={"Method_" + item.id} className="text-center" />
                                                                    <label htmlFor={"radio_method_notpref_" + item.id}></label>
                                                                </div>
                                                                <div className="radio-wrap text-center mr-3">
                                                                    <div className="radio-label-fake text-center">Preferred</div>
                                                                    <input type="radio" id={"radio_method_pref_" + item.id} value={"7_" + item.id} name={"Method_" + item.id} className="text-center" />
                                                                    <label htmlFor={"radio_method_pref_" + item.id}></label>
                                                                </div>
                                                                <div className="radio-wrap text-center mr-3">
                                                                    <div className="radio-label-fake text-center">Best Choice</div>
                                                                    <input type="radio" id={"radio_method_bestchoice_" + item.id} value={"9_" + item.id} name={"Method_" + item.id} className="text-center" />
                                                                    <label htmlFor={"radio_method_bestchoice_" + item.id}></label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))
                                                }
                                            </div>
                                        </div>
                                    </div>

                                    <div className="card card-primary">
                                        <div className="card-header">
                                            <h3 className="card-title">
                                                How often do you prefer to take the treatment?
                                            </h3>
                                        </div>
                                        <div className="card-body">
                                            <div className="row">
                                                {
                                                    datas.map((item,indx)=>(
                                                        <div key={indx} className="col-md-4">
                                                            <label>{item.name}</label>
                                                            <div className="form-group fs-10">
                                                                <div className="radio-wrap text-center mr-3">
                                                                    <div className="radio-label-fake text-center">Never</div>
                                                                    <input type="radio" id={"radio_freq_never_" + item.id} value={"1_" + item.id} name={"Freq_" + item.name} className="text-center" />
                                                                    <label htmlFor={"radio_freq_never_" + item.id}></label>
                                                                </div>
                                                                <div className="radio-wrap text-center mr-3">
                                                                    <div className="radio-label-fake text-center">Not Preferred</div>
                                                                    <input type="radio" id={"radio_freq_notpref_" + item.id} value={"3_" + item.id} name={"Freq_" + item.name} className="text-center" />
                                                                    <label htmlFor={"radio_freq_notpref_" + item.id}></label>
                                                                </div>
                                                                <div className="radio-wrap text-center mr-3">
                                                                    <div className="radio-label-fake text-center">Preferred</div>
                                                                    <input type="radio" id={"radio_freq_pref_" + item.id} value={"7_" + item.id} name={"Freq_" + item.name} className="text-center" />
                                                                    <label htmlFor={"radio_freq_pref_" + item.id}></label>
                                                                </div>
                                                                <div className="radio-wrap text-center mr-3">
                                                                    <div className="radio-label-fake text-center">Best Choice</div>
                                                                    <input type="radio" id={"radio_freq_bestchoice_" + item.id} value={"9_" + item.id} name={"Freq_" + item.name} className="text-center" />
                                                                    <label htmlFor={"radio_freq_bestchoice_" + item.id}></label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))
                                                }
                                            </div>
                                        </div>
                                        <div className="card-footer">
                                            <button type="submit" className="btn btn-success"><i className="fas fa-save"></i> Enter</button>
                                            {error && <><span style={{ color: 'red', fontsize: '20px', marginLeft: '20px' }}>{error}</span></>}
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>

                
                {<Footer />}
            </div>
        </>
    );
}

export default Preference;

import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { setUserSession } from './Common';

function Login() {
    let navigate = useNavigate();
    const username = useFormInput('');
    const password = useFormInput('');
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    // const url = 'http://107.20.165.187:8080/ihealth/token/generate-token';
    // handle button click of login form
    const handleLogin = (event) => {
        event.preventDefault();
        setError(null);
        setLoading(true);
        axios.post('http://18.213.251.171:8080/ihealth-0.0.2/token/generate-token', { password: password.value, username: username.value }).then(response => {
            setLoading(false);
            setUserSession(response.data.content.token, response.data.content);
            localStorage.removeItem('method');
            localStorage.removeItem('freq');
            localStorage.removeItem('disease');
            localStorage.removeItem('medicines');
            localStorage.removeItem('medDetails');
            // console.log(response.data);
            navigate('/Dashboard');
        }).catch(error => {
            setLoading(false);
            // console.log(error.data);
            setError("Wrong credentials. Please check and try again");
        });
    }

    return (
        <>
            <div className="login-page">
                <div className="login-box">
                    <div className="card card-outline card-primary">
                        <div className="card-header text-center box-profile">
                            <div className="text-center">
                                <img className="img-fluid" src="assets/dist/img/logo.png" alt="logo" />
                            </div>
                        </div>
                        <div className="card-body">
                            <p className="login-box-msg">Sign in to start your session</p>
                            {error && <><small style={{ color: 'red' }}>{error}</small><br /></>}
                            <form onSubmit={handleLogin}>
                                <div className="input-group mb-3">
                                    <input type="email" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$" className="form-control" {...username} name="Email" placeholder="Email" autoComplete="off" required />
                                    <div className="input-group-append">
                                        <div className="input-group-text">
                                            <span className="fas fa-envelope"></span>
                                        </div>
                                    </div>
                                </div>
                                <div className="input-group mb-3">
                                    <input type="password" className="form-control" {...password} name="Password" placeholder="Password" required />
                                    <div className="input-group-append">
                                        <div className="input-group-text">
                                            <span className="fas fa-lock"></span>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-4">
                                        <button type="submit" className="btn btn-primary btn-block" disabled={loading}>{loading ? 'Loading...' : 'Sign In'}</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

const useFormInput = initialValue => {
    const [value, setValue] = useState(initialValue);
   
    const handleChange = e => {
        setValue(e.target.value);
    }
    return {
        value,
        onChange: handleChange
    }
}
export default Login;

import React from 'react';

function Footer() {
  return (
    <>
        <footer className="main-footer">
            <div className="float-right d-none d-sm-block">
                <b>Version</b> 1.0.0
            </div>
            <strong>Copyright &copy; 2014-2021 <a href="#"></a></strong> All rights reserved.
        </footer>
    </>
  );
}

export default Footer;
